import {LocaleService} from '@webolucio/core';
import { CtMenuState } from '@webolucio/userspace';

declare module '@webolucio/userspace' {
	interface CtMenuState {
		isOpen: boolean;
	}
}

LocaleService.getService().registerTranslations('app-userspace', async function getTranslationsFiles(locale: string) {
	const module = await import(`./i18n/translations/${locale}.json`);

	return module.default || module || {};
});
