import '@webolucio/core/security';
import './augmentation';
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { SnackbarProvider } from 'notistack';
// Webolucio imports
import { StoreService, DialogProvider, LanguageProvider, LocaleService, UploadProvider, initializeCore } from '@webolucio/core';
import { IconDefinitionProvider, initializeIcons } from '@webolucio/icons';
import { initializeForm } from '@webolucio/form';
import { Dialog, ErrorBoundary, WebolucioRouterProvider, initializeWeb } from '@webolucio/web';
import { initializeFormWeb } from '@webolucio/form-web';
import { initializeUserspace, routes } from '@webolucio/userspace';
// External imports
import ErrorComponent from './components/containers/ErrorBoundary';
import './main.scss';

initializeCore();
initializeForm();
initializeIcons();
initializeWeb();
initializeFormWeb();
initializeUserspace();

export const MOUNT_NODE = document.getElementById('root') as HTMLElement;

function AppContainer() {
	return (
		<StrictMode>
			<ErrorBoundary errorComponent={ErrorComponent}>
				<Provider store={StoreService.getService().getStore()}>
					<LanguageProvider translations={LocaleService.getService().translations}>
						<UploadProvider>
							<IconDefinitionProvider>
								<SnackbarProvider>
									<DialogProvider dialogComponent={Dialog}>
										<WebolucioRouterProvider routes={routes} />
									</DialogProvider>
								</SnackbarProvider>
							</IconDefinitionProvider>
						</UploadProvider>
					</LanguageProvider>
				</Provider>
			</ErrorBoundary>
		</StrictMode>
	);
}

const root = createRoot(MOUNT_NODE);

LocaleService.getService()
	.loadTranslations()
	.then(() => root.render(<AppContainer />));
