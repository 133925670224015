import { FormattedMessage } from 'react-intl';
import { commonMessages } from '../../../i18n';
// Local imports
import messages from './messages';
import classes from './index.module.scss';
// Assets
import Illustration from './assets/fixing-bugs.svg';

function ErrorBoundary() {
	return (
		<div className={classes.root}>
			<div className={classes.dialog}>
				<div className={classes.illustration}>
					<Illustration />
				</div>
				<h1>
					<FormattedMessage {...messages.title} />
				</h1>
				<p>
					<FormattedMessage {...commonMessages.description} />
				</p>
			</div>
		</div>
	);
}

export default ErrorBoundary;
